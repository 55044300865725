import React from 'react';

import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';
import BpkText from '@skyscanner/backpack-web/bpk-component-text';

import stayLength from 'common/src/services/guest-stay/stay-length';
import type { I18nService } from 'common/src/types/i18n';

import { withI18n } from '../../../../../skyscanner-application/i18n';
import calculatePrice from '../../calculatePrice';
import { withPriceData } from '../../../PriceDataProvider';

import type { Maybe } from '../../../../types';
import type { DateMedianPrice } from '../../../../../types/flexible-dates-response';

import STYLES from './PriceSummary.scss';

type Props = {
  checkIn: Date;
  checkOut: Date;
  i18n: I18nService;
  priceData: Maybe<DateMedianPrice>;
};

const cls = cssModules(STYLES);

const PriceSummary = ({
  checkIn,
  checkOut,
  i18n: { formatCurrency, formatDate, translate, translatePlural },
  priceData,
}: Props) => {
  const nightCount = stayLength({
    checkIn,
    checkOut,
  });
  const { averagePrice, minPrice } = calculatePrice(
    checkIn,
    checkOut,
    priceData,
    nightCount,
    formatDate,
  );
  const showCalculatedPrice = averagePrice !== 0;

  return (
    <div className={cls('PriceSummary')}>
      {showCalculatedPrice && averagePrice && (
        <BpkText tagName="p">
          {translate('DateRangeSelector_applyLabel_pricePerNight', {
            cost: formatCurrency(averagePrice),
          })}
        </BpkText>
      )}
      <div className={cls('PriceSummary__night')}>
        <BpkText tagName="p">
          {nightCount &&
            translatePlural(
              'SelectionSummary_label_nights',
              nightCount,
              'number',
            )}
          &nbsp;
          <span>
            {showCalculatedPrice &&
              minPrice &&
              translate('DateRangeSelector_applyLable_FromPrice', {
                0: formatCurrency(minPrice),
              })}
          </span>
        </BpkText>
      </div>
    </div>
  );
};

export default withI18n(withPriceData(PriceSummary));
