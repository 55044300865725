import React from 'react';

import { BpkButtonV2 } from '@skyscanner/backpack-web/bpk-component-button';
import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';
import BpkText from '@skyscanner/backpack-web/bpk-component-text';

import type { I18nService } from 'common/src/types/i18n';

import { withI18n } from '../../../../skyscanner-application/i18n';

import PriceSummary from './PriceSummary';

import type { ButtonClick, Maybe } from '../../../types';

import STYLES from './ApplyFooter.scss';

type Props = {
  checkIn?: Maybe<Date>;
  checkOut?: Maybe<Date>;
  disabled?: boolean;
  i18n: I18nService;
  onApply: ButtonClick;
};

const cls = cssModules(STYLES);

const ApplyFooter = ({
  checkIn,
  checkOut,
  disabled,
  i18n: { translate },
  onApply,
}: Props) => (
  <div className={cls('ApplyFooter')}>
    {checkIn && checkOut && (
      <PriceSummary checkIn={checkIn} checkOut={checkOut} />
    )}
    {!checkOut && (
      <div className={cls('ApplyFooter__selectLabel')}>
        <BpkText tagName="p">
          {translate('label_calendar_select_checkIn_date_footer')}
        </BpkText>
      </div>
    )}
    <div>
      <BpkButtonV2
        id="applyFooter__apply"
        onClick={onApply}
        className={cls('ApplyFooter__applyButton')}
        disabled={disabled}
      >
        {translate('HotelStay_label_Done')}
      </BpkButtonV2>
    </div>
  </div>
);

export default withI18n(ApplyFooter);
