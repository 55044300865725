import React from 'react';

type Props = {
  children: any;
  fetchData: any;
  updateOn?: any;
};

type State = {
  result: any;
};

const LoadingResult = () => ({
  isLoading: true,
  value: null,
  error: null,
});

const SuccessResult = (value: any) => ({
  isLoading: false,
  value,
  error: null,
});

const ErrorResult = (error: Object) => ({
  isLoading: false,
  value: null,
  error,
});

class DataProvider extends React.Component<Props, State> {
  mounted: boolean;

  constructor(props: Props) {
    super(props);

    this.mounted = false;
    this.state = {
      result: LoadingResult(),
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.fetchData();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.updateOn !== this.props.updateOn) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  setResult(result: any) {
    if (this.mounted) {
      this.setState({ result });
    }
  }

  fetchData() {
    this.setResult(LoadingResult());
    this.props
      .fetchData()
      .then((value: any) => this.setResult(SuccessResult(value)))
      .catch((err: Error) => this.setResult(ErrorResult(err)));
  }

  render() {
    const { result } = this.state;
    return <React.Fragment>{this.props.children(result)}</React.Fragment>;
  }
}

export default DataProvider;
export { LoadingResult, SuccessResult, ErrorResult };
