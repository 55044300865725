import React, { Component } from 'react';
import type { ReactNode } from 'react';

import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';
import { BpkButtonV2 } from '@skyscanner/backpack-web/bpk-component-button';
import BpkDialog from '@skyscanner/backpack-web/bpk-component-dialog';
import BpkSmallInfoCircleIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/information-circle';
import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import type { I18nService } from 'common/src/types/i18n';

import { getApplicationElement } from '../../../Common/ApplicationElements';
import { withI18n } from '../../../../skyscanner-application/i18n';

import STYLES from './PriceInfoDialog.scss';

type Props = {
  i18n: I18nService;
  dismissible?: boolean;
  headerIcon?: ReactNode;
};
type State = {
  isOpen: boolean;
};

const defaultProps = {
  dismissible: true,
  headerIcon: null,
};
const cls = cssModules(STYLES);

class PriceInfoDialog extends Component<Props, State> {
  static defaultProps = defaultProps;

  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  onOpen = () => {
    if (!this.state.isOpen) {
      this.setState({
        isOpen: true,
      });
    }
  };

  onClose = () => {
    if (this.state.isOpen) {
      this.setState({
        isOpen: false,
      });
    }
  };

  render() {
    const { translate } = this.props.i18n;
    return (
      <div>
        <BpkSmallInfoCircleIcon
          onClick={this.onOpen}
          className={cls('DialogContainer__outerIcon')}
        />
        {/* @ts-expect-error [TODO]: missing ariaLabel properties */}
        <BpkDialog
          id="priceInfo-popover-modal"
          className={cls('DialogContainer')}
          isOpen={this.state.isOpen}
          onClose={this.onClose}
          getApplicationElement={getApplicationElement}
          headerIcon={this.props.headerIcon}
          dismissible={this.props.dismissible}
        >
          <div className={cls('ScrollableDateRangeSelector__info--title')}>
            <BpkText textStyle={TEXT_STYLES.heading3} tagName="p">
              {translate('label_calendar_price_information_title')}
            </BpkText>
          </div>
          <div className={cls('ScrollableDateRangeSelector__info--content')}>
            <BpkText tagName="p">
              {translate('label_calendar_price_information_content')}
            </BpkText>
          </div>
          <BpkButtonV2
            className={cls('DialogContainer__button')}
            onClick={this.onClose}
          >
            {translate('Reviews_GotIt')}
          </BpkButtonV2>
        </BpkDialog>
      </div>
    );
  }
}

export default withI18n(PriceInfoDialog);
