import type { ComponentType } from 'react';
import React from 'react';

import type { Maybe } from 'common/src/types/utils';

import displayName from '../../display-name';

import type { DateMedianPrice } from '../../../types/flexible-dates-response';

const PriceDataContext = React.createContext({});

const withPriceData = <P extends {}>(Component: ComponentType<P>) => {
  const component = (props: Omit<P, 'priceData'>) => (
    <PriceDataContext.Consumer>
      {(priceData) => (
        <Component
          // @ts-expect-error we expect that priceData can be overwritten in the unit tests
          priceData={(priceData?.value ?? null) as Maybe<DateMedianPrice>}
          {...(props as P)}
        />
      )}
    </PriceDataContext.Consumer>
  );
  component.displayName = displayName(Component);

  return component;
};

export { withPriceData, PriceDataContext };
