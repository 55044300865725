import React, { Component } from 'react';

import { LocalStorage } from 'saddlebag-localstorage';

import BpkText from '@skyscanner/backpack-web/bpk-component-text';
import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import PageTypes from 'common/src/pageTypes';
import { defaultGuestsAndRooms } from 'common/src/services/guest-stay/default-guests-and-rooms';
import stayLength from 'common/src/services/guest-stay/stay-length';
import type { Configs } from 'common/src/types/configs';
import type { I18nService } from 'common/src/types/i18n';
import type { UserContext } from 'common/src/types/user-context';
import type { Stay } from 'common/src/types/utils';
import { now } from 'common/src/utils/wrap-date';

import { withMetrics } from '../../skyscanner-application/application-metrics';
import { withConfiguration } from '../../skyscanner-application/configuration';
import { withElementEventTracker } from '../../skyscanner-application/element-events';
import { withI18n } from '../../skyscanner-application/i18n';
import {
  ACTION_TYPE,
  ENTITY_TYPE,
  CHANGED_CONDITION,
} from '../../skyscanner-application/minievents/constants';
import {
  buildAdditionalInfoDayViewSearch,
  buildAdditionalInfoSearchConditionChanged,
} from '../../skyscanner-application/minievents/hotels-action';
import { withUserContext } from '../UserContext';

import AutosuggestDataProvider from './AutosuggestDataProvider';
import ChappedLayout from './ChappedLayout';
import ChappedLayoutSEO from './ChappedLayoutSEO';
import ExpandableLayout from './ExpandableLayout';
import HorizontalLayout from './HorizontalLayout';
import PriceDataProvider from './PriceDataProvider';

import type {
  ElementEventTracker,
  Metrics,
} from '../../skyscanner-application/types';
import type {
  Destination,
  Maybe,
  SearchDestination,
  SearchSuggestion,
  ObjectMapping,
} from '../types';

import STYLES from './SearchControls.scss';

const cls = cssModules(STYLES);

const LAYOUT: ObjectMapping = {
  CHAPPED: 'chapped',
  HORIZONTAL: 'horizontal',
  EXPANDABLE: 'expandable',
};

const SEARCH_ACTION_TYPE: ObjectMapping = {
  [PageTypes.SEARCH]: ACTION_TYPE.DAY_VIEW_SEARCH,
  [PageTypes.HOME]: ACTION_TYPE.HOME_PAGE_SEARCH,
  [PageTypes.DETAILS]: ACTION_TYPE.HOTEL_DETAIL_SEARCH,
  [PageTypes.DEALS]: ACTION_TYPE.HOTEL_DEALS_SEARCH,
};

type Props = {
  i18n: I18nService;
  elementEventTracker: ElementEventTracker;
  onSubmit: Function;
  submittingForm?: boolean;
  destinationLabel: string;
  lightLabel?: boolean;
  metrics: Metrics;
  openCheckinSelector?: boolean;
  freeCancellation?: boolean;
  /* eslint-disable react/no-unused-prop-types */
  destination?: Destination;
  stay?: Stay;
  prefilledDestination?: Destination;
  showPreference?: boolean;
  /* eslint-enable react/no-unused-prop-types */
  arrangeInline?: boolean;
  masonry?: boolean;
  isMobile?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  searchWithChildren?: boolean;
  shouldAutoSearch?: boolean;
  className?: string;
  layout?: keyof typeof LAYOUT;
  userContext: UserContext;
  configs: Configs;
};

type State = {
  destination?: Destination | SearchSuggestion;
  checkInDate: Maybe<Date>;
  checkOutDate: Maybe<Date>;
  adults: number;
  rooms: number;
  childrenAges: string[];
  freeCancellation?: Maybe<boolean>;
  openCheckinSelector?: boolean;
  showPreference?: boolean;
  currentProps: {
    destination?: Destination;
    stay: Maybe<Stay>;
    prefilledDestination?: Destination;
  };
  urlFilters: null;
  miniEventFilters: null;
  destinationInput: Maybe<string>;
  validDestination: boolean;
};

const defaultProps = {
  submittingForm: false,
  lightLabel: true,
  openCheckinSelector: false,
  freeCancellation: false,
  arrangeInline: false,
  masonry: false,
  shouldAutoSearch: false,
};

const localStorage = new LocalStorage('hotelsWebsiteLocalStorage');
const buildSearchState = (props: Props, state?: State) => {
  const {
    destination,
    freeCancellation,
    prefilledDestination,
    showPreference,
    stay,
  } = props;
  let checkIn;
  let checkOut;
  let childrenAges: string[] = [];
  let { numberOfAdults, numberOfRooms } = defaultGuestsAndRooms();
  if (stay) {
    ({ checkIn, checkOut, childrenAges, numberOfAdults, numberOfRooms } = stay);
  }

  const { destination: existingDestination } = state || {};

  return {
    destination: destination || existingDestination || prefilledDestination,
    checkInDate: checkIn,
    checkOutDate: checkOut,
    adults: numberOfAdults,
    rooms: numberOfRooms,
    childrenAges,
    freeCancellation,
    showPreference,
    currentProps: {
      destination,
      prefilledDestination,
      stay,
    },
  };
};

const searchPropsAreChanging = (nextProps: Props, prevState: State) => {
  const {
    destination: nextDestination,
    prefilledDestination: nextPrefilledDestination,
    stay: nextStay,
  } = nextProps;
  let nextCheckIn;
  let nextCheckOut;
  // @ts-expect-error Property 'nextNumberOfAdults' and 'nextNumberOfRooms' do not exist on type
  // TODO: need to be fixed, it's weird usage and might be caused bugs
  let { nextNumberOfAdults, nextNumberOfRooms } = defaultGuestsAndRooms();

  if (nextStay) {
    ({
      checkIn: nextCheckIn,
      checkOut: nextCheckOut,
      numberOfAdults: nextNumberOfAdults,
      numberOfRooms: nextNumberOfRooms,
    } = nextStay);
  }

  if (!nextCheckIn && !nextCheckOut) {
    return false;
  }

  const { entity: nextEntity, entityId: nextEntityId } = nextDestination || {};
  const { entityId: nextPrefilledEntityId } = nextPrefilledDestination || {};

  const {
    destination,
    prefilledDestination,
    stay: prevStay,
  } = prevState.currentProps;
  let checkIn;
  let checkOut;
  let { numberOfAdults, numberOfRooms } = defaultGuestsAndRooms();

  if (prevStay) {
    ({ checkIn, checkOut, numberOfAdults, numberOfRooms } = prevStay);
  }
  const { entityId: prefilledEntityId } = prefilledDestination || {};
  const { entity, entityId } = destination || {};

  return (
    entityId !== nextEntityId ||
    entity !== nextEntity ||
    prefilledEntityId !== nextPrefilledEntityId ||
    checkIn !== nextCheckIn ||
    checkOut !== nextCheckOut ||
    numberOfAdults !== nextNumberOfAdults ||
    numberOfRooms !== nextNumberOfRooms
  );
};

const hasDestination = (destination?: Destination) =>
  !!(destination && destination.entityId);

class SearchControls extends Component<Props, State> {
  static defaultProps = defaultProps;

  searchItems: any[];

  constructor(props: Props) {
    super(props);

    this.state = {
      destinationInput: null,
      ...buildSearchState(props),
      openCheckinSelector: this.props.openCheckinSelector,
      urlFilters: null,
      miniEventFilters: null,
      validDestination: true,
    };

    this.searchItems = [];
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (searchPropsAreChanging(nextProps, prevState)) {
      return buildSearchState(nextProps, prevState);
    }

    return null;
  }

  onDestinationSuggestionsRequested = ({ value }: { value: string }) => {
    this.setState({ destinationInput: value });
  };

  onChangeInputValue = (value: string) => {
    const { destinationInput } = this.state;
    if (destinationInput !== value) {
      this.setState({ destinationInput: value });
    }
  };

  onDestinationChange = ({ suggestion }: { suggestion: SearchSuggestion }) => {
    this.setState(
      {
        destination: suggestion,
      },
      () => this.validateForm(),
    );
  };

  onChangeOpenCheckinSelector = (isOpen: boolean) => {
    const { isMobile } = this.props;
    const isAutoOpenCalender = !isMobile;

    if (isAutoOpenCalender) {
      this.setState({
        openCheckinSelector: isOpen,
      });
    }
  };

  onRecentSearchesChange = (item: SearchDestination) => {
    const { checkInDate: checkInState, checkOutDate: checkOutState } =
      this.state;
    const {
      adults,
      checkInDate: checkInRecent,
      checkOutDate: checkOutRecent,
      childrenAges,
      rooms,
    } = item;

    if (checkInRecent) {
      const futureDate = stayLength({
        checkIn: now(),
        checkOut: checkInRecent,
      });
      const checkInDate = futureDate >= 0 ? checkInRecent : checkInState;
      const checkOutDate = futureDate >= 0 ? checkOutRecent : checkOutState;
      this.onDatesChanged(checkInDate, checkOutDate);
      this.setState({
        adults,
        childrenAges,
        rooms,
      });
    }
  };

  onDatesChanged = (checkInDate: Maybe<Date>, checkOutDate: Maybe<Date>) => {
    this.setState({
      checkInDate,
      checkOutDate,
    });
  };

  onDatesRangeApply = (checkInDate: Date, checkOutDate: Date) => {
    this.onDatesChanged(checkInDate, checkOutDate);
    if (this.props.shouldAutoSearch) {
      this.traceConditionChangedEvent({
        changedCondition: CHANGED_CONDITION.DATE_RANGE,
      });
      setTimeout(() => {
        this.triggerSearch();
      }, 0);
    }
  };

  onDatesRangeApplyNoAutoSearch = (checkInDate: Date, checkOutDate: Date) => {
    this.onDatesChanged(checkInDate, checkOutDate);
    this.traceConditionChangedEvent({
      changedCondition: CHANGED_CONDITION.DATE_RANGE,
    });
  };

  getGuestsRoomsChildren = ({
    adultChanged,
    adults,
    childrenAges,
    childrenAgesChanged,
    roomNumChanged,
    rooms,
  }: {
    rooms: number;
    adults: number;
    childrenAges: string[];
    adultChanged?: Maybe<boolean>;
    childrenAgesChanged?: Maybe<boolean>;
    changedCondition?: Maybe<string>;
    roomNumChanged?: Maybe<boolean>;
  }) => {
    this.setState(
      {
        rooms,
        adults,
        childrenAges,
      },
      () => {
        if (this.props.shouldAutoSearch) {
          this.traceConditionChangedEvent({
            adultChanged,
            changedCondition: CHANGED_CONDITION.GUEST_OR_ROOMS,
            childrenAgesChanged,
            roomNumChanged,
          });
          this.triggerSearch();
        }
      },
    );
  };

  getGuestsRoomsChildrenNoAutoSearch = ({
    adultChanged,
    adults,
    childrenAges,
    childrenAgesChanged,
    roomNumChanged,
    rooms,
  }: {
    rooms: number;
    adults: number;
    childrenAges: string[];
    adultChanged?: Maybe<boolean>;
    childrenAgesChanged?: Maybe<boolean>;
    changedCondition?: Maybe<string>;
    roomNumChanged?: Maybe<boolean>;
  }) => {
    this.setState(
      {
        rooms,
        adults,
        childrenAges,
      },
      () => {
        this.traceConditionChangedEvent({
          adultChanged,
          changedCondition: CHANGED_CONDITION.GUEST_OR_ROOMS,
          childrenAgesChanged,
          roomNumChanged,
        });
      },
    );
  };

  traceConditionChangedEvent = ({
    adultChanged,
    changedCondition,
    childrenAgesChanged,
    roomNumChanged,
  }: {
    adultChanged?: Maybe<boolean>;
    childrenAgesChanged?: Maybe<boolean>;
    changedCondition: string;
    roomNumChanged?: Maybe<boolean>;
  }) => {
    if (this.props.layout === LAYOUT.CHAPPED) {
      const { childrenAges, destination, rooms } = this.state;
      this.props.elementEventTracker.trackHotelsAction(
        ACTION_TYPE.SEARCH_CONDITION_CHANGED,
        buildAdditionalInfoSearchConditionChanged({
          hotelId: destination && destination.entityId,
          changedCondition,
          adultChanged,
          childrenAgesChanged,
          roomNumChanged,
          rooms,
          isSearchWithChildren: !!childrenAges && childrenAges.length > 0,
        }),
      );
    }
  };

  onSubmitClick = () => {
    this.onChangeOpenCheckinSelector(false);
    const { elementEventTracker, metrics } = this.props;
    try {
      metrics.searchClicked();
      const { destination, miniEventFilters } = this.state;
      const searchWithChildren: boolean =
        this.state.childrenAges && this.state.childrenAges.length > 0;

      if (!destination) {
        return;
      }
      const { entity, entityId, type } = destination;
      const additionalInfoDayViewSearch = {
        entityID: entityId ? parseInt(entityId, 10) : undefined,
        entityName: entity,
        isSearchWithChildren: searchWithChildren,
        entityType: type && ENTITY_TYPE[type.toUpperCase()],
        filters: null,
      };

      if (miniEventFilters) {
        additionalInfoDayViewSearch.filters = miniEventFilters;
      }

      const actionType = SEARCH_ACTION_TYPE[this.props.userContext.pageType];
      if (!actionType) {
        // TODO: we may also need to track the search action on the details page
        return;
      }
      elementEventTracker.trackHotelsAction(
        actionType,
        buildAdditionalInfoDayViewSearch(additionalInfoDayViewSearch),
      );

      elementEventTracker.trackSmartMetric(actionType);
    } catch (e) {
      // Quietly catching all errors to make sure the form is submitted
    }
  };

  onFormSubmit = (e?: any) => {
    if (e) {
      e.preventDefault();
    }

    const { metrics, onSubmit } = this.props;
    if (this.validateForm()) {
      const {
        adults,
        checkInDate,
        checkOutDate,
        childrenAges,
        destination,
        freeCancellation,
        rooms,
        urlFilters,
      } = this.state;

      this.searchItems.push({
        destination,
        checkInDate,
        checkOutDate,
        adults,
        childrenAges,
        rooms,
      });
      localStorage.trySetValue(
        'recentSearchItems',
        JSON.stringify(this.searchItems.reverse().slice(0, 5)),
      );

      metrics.searchSubmitted();
      onSubmit({
        destination,
        checkIn: checkInDate,
        checkOut: checkOutDate,
        numberOfAdults: adults,
        numberOfRooms: rooms,
        childrenAges,
        freeCancellation,
        filters: urlFilters,
      });
    } else {
      metrics.searchValidationFailed();
    }
  };

  triggerSearch = () => {
    this.onSubmitClick();
    this.onFormSubmit();
  };

  getPreference = (filters: any) => {
    const { miniEventFilters, urlFilters } = filters;
    const urlFilterKeys = Object.keys(urlFilters);
    this.setState({
      urlFilters: urlFilterKeys.length > 0 ? urlFilters : null,
      miniEventFilters: urlFilterKeys.length > 0 ? miniEventFilters : null,
    });
  };

  onSuggestionSelected = (item: SearchDestination) => {
    this.onDestinationChange(item);
    const { isFromRecentSearch } = item;
    if (isFromRecentSearch) {
      this.onRecentSearchesChange(item);
    }
    if (this.props.shouldAutoSearch || isFromRecentSearch) {
      setTimeout(() => {
        this.triggerSearch();
      }, 0);
    }
  };

  validateForm() {
    const { destination } = this.state;
    const hasValue = hasDestination(destination);
    this.setState({
      validDestination: hasValue,
    });
    return hasValue;
  }

  render() {
    const {
      arrangeInline,
      className,
      configs,
      destinationLabel,
      freeCancellation,
      i18n: { translate },
      layout,
      lightLabel,
      masonry,
      onSubmit,
      shouldAutoSearch,
      stay,
      submittingForm,
      userContext,
    } = this.props;
    const {
      adults,
      checkInDate,
      checkOutDate,
      childrenAges,
      destination,
      destinationInput,
      openCheckinSelector,
      rooms,
      showPreference,
      validDestination,
    } = this.state;

    const noCheckDates = !stay || (stay && !stay.checkIn && !stay.checkOut);
    const seoVariantConfig =
      userContext.enableSEOVariantHotelsDetailsPage &&
      configs.enableRoomsRatesSEOVariant;

    return (
      <PriceDataProvider destination={destination}>
        {!validDestination && (
          <div
            className={cls(
              arrangeInline
                ? 'SearchControls__destinationErr--inline'
                : 'SearchControls__destinationErr--default',
              noCheckDates && 'SearchControls__destinationErr--noMargin',
            )}
          >
            <BpkText className={cls('SearchControls__destinationErr--message')}>
              {translate('SearchControls_label_Destination_error')}
            </BpkText>
          </div>
        )}
        <form
          data-test-id="search-controls"
          onSubmit={this.onFormSubmit}
          className={cls(arrangeInline && 'SearchControls', className)}
        >
          <AutosuggestDataProvider input={destinationInput}>
            {(suggestions, onClearSuggestions) => {
              if (layout === LAYOUT.HORIZONTAL) {
                return (
                  <HorizontalLayout
                    suggestions={suggestions}
                    destination={destination}
                    destinationLabel={destinationLabel}
                    submittingForm={submittingForm}
                    lightLabel={lightLabel}
                    checkInDate={checkInDate}
                    checkOutDate={checkOutDate}
                    adults={adults}
                    rooms={rooms}
                    childrenAges={childrenAges}
                    onChangeInputValue={this.onChangeInputValue}
                    onDatesRangeApply={this.onDatesRangeApply}
                    onSuggestionsFetchRequested={
                      this.onDestinationSuggestionsRequested
                    }
                    onSuggestionSelected={this.onSuggestionSelected}
                    getGuestsRoomsChildren={this.getGuestsRoomsChildren}
                    onChangeOpenCheckinSelector={
                      this.onChangeOpenCheckinSelector
                    }
                    onClearSuggestions={onClearSuggestions}
                    validDestination={validDestination ? null : false}
                    showSearchButton={!shouldAutoSearch}
                    arrangeInline={arrangeInline}
                  />
                );
              }

              if (layout === LAYOUT.CHAPPED && seoVariantConfig) {
                return (
                  <ChappedLayoutSEO
                    lightLabel={lightLabel}
                    checkInDate={checkInDate}
                    checkOutDate={checkOutDate}
                    adults={adults}
                    rooms={rooms}
                    childrenAges={childrenAges}
                    onDatesChanged={this.onDatesRangeApplyNoAutoSearch}
                    onSubmit={this.triggerSearch}
                    getGuestsRoomsChildren={
                      this.getGuestsRoomsChildrenNoAutoSearch
                    }
                    onChangeOpenCheckinSelector={
                      this.onChangeOpenCheckinSelector
                    }
                    openCheckinSelector={openCheckinSelector}
                  />
                );
              }

              if (layout === LAYOUT.CHAPPED) {
                return (
                  <ChappedLayout
                    suggestions={suggestions}
                    destination={destination}
                    destinationLabel={destinationLabel}
                    lightLabel={lightLabel}
                    searchButtonLabel={translate('SearchControls_label_Submit')}
                    checkInDate={checkInDate}
                    checkOutDate={checkOutDate}
                    adults={adults}
                    rooms={rooms}
                    childrenAges={childrenAges}
                    onSuggestionsFetchRequested={
                      this.onDestinationSuggestionsRequested
                    }
                    onSuggestionSelected={this.onSuggestionSelected}
                    getGuestsRoomsChildren={this.getGuestsRoomsChildren}
                    onDatesChanged={this.onDatesRangeApply}
                    onSubmit={onSubmit}
                    onClearSuggestions={onClearSuggestions}
                    arrangeInline={arrangeInline}
                  />
                );
              }

              return (
                <ExpandableLayout
                  suggestions={suggestions}
                  destination={destination}
                  destinationLabel={destinationLabel}
                  submittingForm={submittingForm}
                  lightLabel={lightLabel}
                  checkInDate={checkInDate}
                  checkOutDate={checkOutDate}
                  adults={adults}
                  rooms={rooms}
                  childrenAges={childrenAges}
                  onSuggestionsFetchRequested={
                    this.onDestinationSuggestionsRequested
                  }
                  onSuggestionSelected={this.onSuggestionSelected}
                  getGuestsRoomsChildren={this.getGuestsRoomsChildren}
                  onDatesChanged={this.onDatesChanged}
                  openCheckinSelector={openCheckinSelector}
                  onChangeOpenCheckinSelector={this.onChangeOpenCheckinSelector}
                  onClearSuggestions={onClearSuggestions}
                  onGetPreference={this.getPreference}
                  onSubmitClick={this.onSubmitClick}
                  showPreference={!!showPreference}
                  freeCancellation={freeCancellation}
                  arrangeInline={arrangeInline}
                  masonry={masonry}
                  validDestination={validDestination ? null : false}
                />
              );
            }}
          </AutosuggestDataProvider>
        </form>
      </PriceDataProvider>
    );
  }
}

export default withElementEventTracker(
  withMetrics(withI18n(withUserContext(withConfiguration(SearchControls)))),
);
export { LAYOUT };
