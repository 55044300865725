import React from 'react';

import BpkLargeCalendarIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/calendar';
import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

import type { I18nService } from 'common/src/types/i18n';

import { withI18n, DATE_FORMAT } from '../../../../skyscanner-application/i18n';

import type { ButtonClick, Maybe } from '../../../types';

import STYLES from './DateRange.scss';

const cls = cssModules(STYLES);

type Props = {
  i18n: I18nService;
  onClickLink: ButtonClick;
  checkInDate: Maybe<Date>;
  checkOutDate: Maybe<Date>;
  className?: string;
};

const DateRange = ({
  checkInDate,
  checkOutDate,
  className,
  i18n: { formatDate },
  onClickLink,
}: Props) => (
  <div
    role="button"
    tabIndex={0}
    onKeyUp={() => {}}
    onClick={onClickLink}
    className={cls('DateRange', className)}
  >
    <BpkLargeCalendarIcon className={cls('DateRange__icon')} />
    <button type="button">
      <BpkText textStyle={TEXT_STYLES.caption} tagName="p">
        {checkInDate
          ? formatDate(checkInDate, DATE_FORMAT.MONTH_DAY_NO_WEEK_ABBR)
          : '--/--'}
      </BpkText>
      <BpkText textStyle={TEXT_STYLES.caption} tagName="p">
        {checkOutDate
          ? formatDate(checkOutDate, DATE_FORMAT.MONTH_DAY_NO_WEEK_ABBR)
          : '--/--'}
      </BpkText>
    </button>
  </div>
);

export default withI18n(DateRange);
