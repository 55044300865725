/* eslint-disable camelcase */

import type {
  AutosuggestSuggestionsOrPopularDestinations,
  AutosuggestSuggestion,
} from '../../types';
import type { AutosuggestSearchResponse } from '../../../types/autosuggest-search-response';

const items = (response: AutosuggestSearchResponse) =>
  response.map((x) => {
    const {
      class: type,
      entity_id: entityId,
      entity_name: entityName,
      hierarchy,
      highlight,
      location,
      pois,
    } = x;
    const latLon = location.split(',');
    const suggestion: AutosuggestSuggestion = {
      label: x.type || '',
      type,
      entityId,
      lat: parseFloat(latLon[0]),
      lon: parseFloat(latLon[1]),
      pois: pois
        ? pois.map(
            (item: {
              entity_id: string;
              entity_name: string;
              class: string;
            }) => ({
              entityId: item.entity_id,
              entity: item.entity_name,
              type: item.class,
              isPoi: true,
            }),
          )
        : [],
    };

    let showEnityName = entityName;
    let showHierarchy = hierarchy;
    if (highlight) {
      const { entity_name: emEntityName, hierarchy: emHierarchy } = highlight;
      showEnityName = emEntityName || entityName;
      showHierarchy = emHierarchy || hierarchy;
    }

    const fields = ['entity', 'city', 'adm2', 'adm1', 'nation'];
    const hierarchyItems = showHierarchy ? showHierarchy.split('|') : [];
    const fieldValues = [showEnityName, ...hierarchyItems];
    fieldValues.forEach((value, i) => {
      if (value !== 'null') {
        // @ts-expect-error TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'AutosuggestSuggestion'.
        suggestion[fields[i]] = value;
      }
    });

    return suggestion;
  });

const popularItems = (response: AutosuggestSearchResponse) => {
  if (!response.length) {
    return [];
  }

  return [
    {
      popularDestinations: items(response),
    },
  ];
};

const autoSuggestItems = (
  response: AutosuggestSearchResponse,
  value?: string,
): AutosuggestSuggestionsOrPopularDestinations =>
  // @ts-expect-error TS2322: Type 'AutosuggestSuggestion[] | { popularDestinations: AutosuggestSuggestion[]; }[]' is not assignable to type 'AutosuggestSuggestionsOrPopularDestinations'.
  value ? items(response) : popularItems(response);

export default autoSuggestItems;
