import React from 'react';

import BpkModal from '@skyscanner/backpack-web/bpk-component-modal';

import { HASH_LOCATION_MODAL } from '../../../../services/routes';
import HashRoute from '../../../HashRoute';
import { getApplicationElement } from '../../../Common/ApplicationElements';
import DestinationSelector from '../DestinationSelector';

import type {
  Maybe,
  AutosuggestSuggestionsOrPopularDestinations,
  Destination,
} from '../../../types';

type Props = {
  destinationLabel: string;
  destination?: Destination;
  lightLabel?: boolean;
  arrangeInline?: boolean;
  valid?: Maybe<boolean>;
  className?: Maybe<string>;
  onSuggestionSelected: Function;
  onSuggestionsFetchRequested: Function;
  onSuggestionsClearRequested: Function;
  suggestions: AutosuggestSuggestionsOrPopularDestinations;
  onChangeOpenCheckinSelector?: Maybe<Function>;
  cancelLabel: string;
  onChangeInputValue?: Maybe<Function>;
};

const DestinationSelectorModal = ({
  arrangeInline,
  cancelLabel,
  className,
  destination,
  destinationLabel,
  lightLabel,
  onChangeInputValue,
  onChangeOpenCheckinSelector,
  onSuggestionSelected,
  onSuggestionsClearRequested,
  onSuggestionsFetchRequested,
  suggestions,
  valid,
}: Props) => (
  <HashRoute
    hash={HASH_LOCATION_MODAL}
    render={({ onClose }: any) => (
      <BpkModal
        id="hotel-destination-modal"
        isOpen
        onClose={onClose}
        title={destinationLabel}
        closeText={cancelLabel}
        getApplicationElement={getApplicationElement}
        closeOnEscPressed
      >
        <DestinationSelector
          className={className}
          destinationLabel={destinationLabel}
          destination={destination}
          lightLabel={lightLabel}
          onSuggestionSelected={(
            item: AutosuggestSuggestionsOrPopularDestinations,
          ) => {
            onClose();
            onSuggestionSelected(item);
          }}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          suggestions={suggestions}
          arrangeInline={arrangeInline}
          isMobile
          onChangeOpenCheckinSelector={onChangeOpenCheckinSelector}
          onChangeInputValue={onChangeInputValue}
          valid={valid}
        />
      </BpkModal>
    )}
  />
);

DestinationSelectorModal.defaultProps = {
  lightLabel: false,
  arrangeInline: false,
  className: null,
  onChangeOpenCheckinSelector: null,
  onChangeInputValue: null,
  valid: null,
};

export default DestinationSelectorModal;
