import React, { Component } from 'react';

import { BpkScrollableCalendarDate } from '@skyscanner/backpack-web/bpk-component-scrollable-calendar';
import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

import type { I18nService } from 'common/src/types/i18n';

import { withI18n, DATE_FORMAT } from '../../skyscanner-application/i18n';
import transformPrice from '../SearchControls/transformPrice';
import { withPriceData } from '../SearchControls/PriceDataProvider';

import type { Maybe } from '../types';
import type { DateMedianPrice } from '../../types/flexible-dates-response';

import STYLES from './CalendarDate.scss';

type Props = {
  i18n: I18nService;
  date: Date;
  minDate: Date;
  maxDate: Date;
  startDate?: Maybe<Date>;
  endDate?: Maybe<Date>;
  isOutside?: boolean;
  priceData: Maybe<DateMedianPrice>;
};

const cls = cssModules(STYLES);

const priceTag = (
  direction: Maybe<string>,
  i18n: I18nService,
  tagPrice: number,
) => transformPrice(i18n, tagPrice).get(direction || 'DEFAULT');

class CalendarDate extends Component<Props> {
  getPriceData = () => {
    const { date, i18n, priceData } = this.props;

    if (!priceData) {
      return {};
    }

    const price =
      priceData[i18n.formatDate(date, DATE_FORMAT.NON_LOCALISED_SHORT)];
    if (!price || !price.median) {
      return {};
    }
    const { direction, median } = price;
    return { direction, ...priceTag(direction, i18n, median) };
  };

  render() {
    const {
      date,
      endDate,
      i18n,
      maxDate,
      minDate,
      priceData,
      startDate,
      ...rest
    } = this.props;
    const { isOutside } = rest;
    // @ts-expect-error TS2339: Property 'direction' does not exist on type '{}'.
    const { direction = '', price = '' } = this.getPriceData();

    return (
      <div
        className={cls(
          !isOutside && 'CalendarDate__dateBg',
          isOutside && price && 'CalendarDate__dateBg--outside',
        )}
      >
        <BpkScrollableCalendarDate date={date} {...rest} />

        {!isOutside && price && (
          <div
            className={cls(
              'CalendarDate__pill',
              direction && direction === 'CHEAP' && 'CalendarDate__pill--cheap',
            )}
          >
            <BpkText textStyle={TEXT_STYLES.caption} tagName="p">
              {!isOutside ? price : ''}
            </BpkText>
          </div>
        )}
      </div>
    );
  }
}

export default withI18n(withPriceData(CalendarDate));
