import React from 'react';

import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import type { I18nService } from 'common/src/types/i18n';

import { withI18n } from '../../../../skyscanner-application/i18n';
import PopularDestination from '../PopularDestination';
import RecentSearches from '../RecentSearches';

import type { PopularDestination as PopularDestinationType } from '../../../types';

import STYLES from './RecentSearchAndPopularsMobile.scss';

type Props = {
  i18n: I18nService;
  suggestion: PopularDestinationType;
  onSuggestionSelected: Function;
  onBlur: Function;
};

const cls = cssModules(STYLES);

const RecentSearchAndPopularsMobile = ({
  i18n: { translate },
  onBlur,
  onSuggestionSelected,
  suggestion,
}: Props) => {
  const hasPopular =
    suggestion.popularDestinations && suggestion.popularDestinations.length > 0;
  return (
    <section>
      <RecentSearches
        onSuggestionSelected={onSuggestionSelected}
        onBlur={onBlur}
        isMobile
      />
      {hasPopular && (
        <section>
          <div className={cls('RecentSearchAndPopularsMobile__popularTitle')}>
            {translate('Auto_Suggest_popularDestination_label')}
          </div>
          <div
            className={cls(
              'RecentSearchAndPopularsMobile__popularDestinations',
            )}
          >
            {suggestion.popularDestinations &&
              suggestion.popularDestinations.map((popular, index) => (
                <PopularDestination
                  key={popular.entityId}
                  popular={popular}
                  index={index}
                  onSuggestionSelected={onSuggestionSelected}
                  onBlur={onBlur}
                  isMobile
                />
              ))}
          </div>
        </section>
      )}
    </section>
  );
};

export default withI18n(RecentSearchAndPopularsMobile);
