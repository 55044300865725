import type { ReactNode } from 'react';
import React from 'react';

import addDays from 'date-fns/addDays';

import { now } from 'common/src/utils/wrap-date';
import type { I18nService } from 'common/src/types/i18n';
import type { Configs } from 'common/src/types/configs';

import { withBackendContext } from '../../backend-context';
import { withConfiguration } from '../../../skyscanner-application/configuration';
import DataProvider from '../../DataProvider';
import { DATE_FORMAT, withI18n } from '../../../skyscanner-application/i18n';

import { PriceDataContext } from './PriceDataContext';

import type { DataFetchingResult, Destination } from '../../types';
import type { DateMedianPrice } from '../../../types/flexible-dates-response';
import type { BackendGateway } from '../../../types/backend-gateway';

type Props = {
  i18n: I18nService;
  children: ReactNode;
  configs: Configs;
  backendGateway: BackendGateway;
  destination?: Destination;
};

class PriceDataProvider extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  fetchPriceData = async () => {
    const {
      configs: { enableCalendarWithPrice },
      i18n: { formatDate },
    } = this.props;

    if (enableCalendarWithPrice) {
      const stay = {
        adults: 2,
        rooms: 1,
        start: formatDate(now(), DATE_FORMAT.NON_LOCALISED_SHORT),
        end: formatDate(addDays(now(), 60), DATE_FORMAT.NON_LOCALISED_SHORT),
      };
      const { backendGateway, destination } = this.props;

      if (destination && destination.entityId) {
        const entityId = `${destination.entityId}`;
        const priceArray = await backendGateway.flexibleDatesResult({
          entityIds: entityId,
          stay,
        });
        return priceArray?.length > 0 ? priceArray[0][entityId] : {};
      }
    }
    return Promise.resolve({});
  };

  render() {
    const { children, destination } = this.props;

    return (
      <DataProvider
        fetchData={this.fetchPriceData}
        updateOn={destination ? destination.entityId : null}
      >
        {(result: DataFetchingResult<DateMedianPrice>) => (
          <PriceDataContext.Provider value={result}>
            {children}
          </PriceDataContext.Provider>
        )}
      </DataProvider>
    );
  }
}

export default withI18n(
  withConfiguration(withBackendContext(PriceDataProvider)),
);
