import type { MouseEvent } from 'react';
import React, { Component } from 'react';

import { LocalStorage } from 'saddlebag-localstorage';

import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';
import {
  BpkButtonV2,
  BUTTON_TYPES,
} from '@skyscanner/backpack-web/bpk-component-button';

import type { I18nService } from 'common/src/types/i18n';

import { withI18n } from '../../../../skyscanner-application/i18n';
import { getSubheadingValue } from '../../searchControlHelper';

import RecentSearchItem from './RecentSearchItem';

import type { Destination, RecentSearch } from '../../../types';

import STYLES from './RecentSearches.scss';

type Props = {
  i18n: I18nService;
  onSuggestionSelected: Function;
  onSuggestionsClearRequested?: Function;
  onBlur: Function;
  isMobile?: boolean;
};

type State = {
  expanded: boolean;
};

const defaultProps = {
  onSuggestionsClearRequested: undefined,
  isMobile: false,
};

const cls = cssModules(STYLES);
const localStorage = new LocalStorage('hotelsWebsiteLocalStorage');

const INITIAL_SHOW_VALUE = 2;
const TERMINAL_SHOW_VALUE = 5;

export const getRecentSearchValue = (destination: Destination) => {
  const entityValue = destination.entity;
  const subValues = getSubheadingValue(destination);
  return `${entityValue}, ${subValues}`;
};

class RecentSearches extends Component<Props, State> {
  static defaultProps = defaultProps;

  constructor(props: Props) {
    super(props);

    this.state = {
      expanded: false,
    };
  }

  toggle = (e: MouseEvent) => {
    e.stopPropagation();
    this.setState((prevState) => ({
      expanded: !prevState.expanded,
    }));
  };

  render() {
    const recentItems = localStorage.tryGetValue('recentSearchItems');
    const recentSearchItemsList =
      (recentItems && JSON.parse(recentItems)) || [];

    const NumRecentSearch = recentSearchItemsList.length;
    if (!NumRecentSearch) {
      return null;
    }

    if (NumRecentSearch === 1 && !recentSearchItemsList[0].destination) {
      return null;
    }
    const { expanded } = this.state;
    const {
      i18n,
      isMobile,
      onBlur,
      onSuggestionSelected,
      onSuggestionsClearRequested,
    } = this.props;

    const showMoreButton = recentSearchItemsList.length > INITIAL_SHOW_VALUE;
    const showMax = expanded ? TERMINAL_SHOW_VALUE : INITIAL_SHOW_VALUE;
    const viewMoreLessLabelKey = expanded
      ? 'Button_viewLess'
      : 'HotelImageGallery_label_viewAll';

    const recentSearchesList = recentSearchItemsList
      .slice(0, showMax)
      .map((item: RecentSearch, index: number) => (
        <RecentSearchItem
          key={item.suggestionValue}
          index={index}
          item={item}
          onBlur={onBlur}
          onSuggestionSelected={onSuggestionSelected}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          isMobile={!!isMobile}
        />
      ));

    return (
      <section>
        <div className={cls('RecentSearches__recentSearchHeader')}>
          <div className={cls('RecentSearches__recentTitle')}>
            {i18n.translate('Auto_Suggest_recentSearch_label')}
          </div>
          {showMoreButton && (
            <div className={cls('RecentSearches__btn')}>
              <BpkButtonV2 type={BUTTON_TYPES.link} onClick={this.toggle}>
                {i18n.translate(viewMoreLessLabelKey)}
              </BpkButtonV2>
            </div>
          )}
        </div>
        <div className={cls('RecentSearches__recentSearchItems')}>
          {recentSearchesList}
        </div>
      </section>
    );
  }
}

export default withI18n(RecentSearches);
