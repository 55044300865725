import React from 'react';

import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';
import BpkText from '@skyscanner/backpack-web/bpk-component-text';

import {
  applyCheckInDateRestrictions,
  applyCheckOutDateRestrictions,
} from 'common/src/services/guest-stay';
import stayLength from 'common/src/services/guest-stay/stay-length';
import type { I18nService } from 'common/src/types/i18n';
import type { Maybe } from 'common/src/types/utils';

import { withI18n } from '../../../skyscanner-application/i18n';
import FormField from '../FormField';
import { withPriceData } from '../PriceDataProvider';

import DatePicker from './DatePicker';

import type { DateMedianPrice } from '../../../types/flexible-dates-response';

import STYLES from './DateRangeSelector.scss';

const cls = cssModules(STYLES);

type Props = {
  i18n: I18nService;
  checkInDate: Date;
  checkOutDate: Date;
  onDatesChanged: Function;
  onChangeOpenCheckinSelector?: Function;
  className?: string;
  formFieldClassName?: string;
  disabled?: boolean;
  openCheckinSelector?: boolean;
  showNights?: boolean;
  showLabel?: boolean;
  lightLabel?: boolean;
  masonry?: boolean;
  priceData: Maybe<DateMedianPrice>;
};

type State = {
  isCheckOutDateOpen: boolean;
};

class DateRangeSelector extends React.Component<Props, State> {
  state = {
    isCheckOutDateOpen: false,
  };

  handleCheckInChange = (newCheckInDate: Date) => {
    const { checkOutDate, onDatesChanged, openCheckinSelector } = this.props;
    const newCheckOutDate = applyCheckOutDateRestrictions(
      newCheckInDate,
      checkOutDate,
    );
    onDatesChanged(newCheckInDate, newCheckOutDate);

    // The check out date selector should open on check in date select as part of an experiment
    // controlled via the openCheckinSelector prop
    if (openCheckinSelector) {
      this.setState({
        isCheckOutDateOpen: true,
      });
    }
  };

  handleCheckOutChange = (newCheckOutDate: Date) => {
    const { checkInDate, onChangeOpenCheckinSelector, onDatesChanged } =
      this.props;
    const newCheckInDate = applyCheckInDateRestrictions(
      checkInDate,
      newCheckOutDate,
    );
    onDatesChanged(newCheckInDate, newCheckOutDate);

    if (onChangeOpenCheckinSelector) {
      onChangeOpenCheckinSelector(false);
    }
  };

  handleCheckOutOpenChange = (isOpen: boolean) => {
    const { onChangeOpenCheckinSelector } = this.props;
    this.setState({
      isCheckOutDateOpen: isOpen,
    });
    if (onChangeOpenCheckinSelector) {
      onChangeOpenCheckinSelector(false);
    }
  };

  handleCheckInOpenChange = (isOpen: boolean) => {
    const { onChangeOpenCheckinSelector, openCheckinSelector } = this.props;
    // WORKAROUND: https://skyscanner.atlassian.net/browse/BD-6540
    if (isOpen && document.activeElement) {
      // @ts-expect-error TS2339: Property 'blur' does not exist on type 'Element'.
      document.activeElement.blur();
    }
    if (onChangeOpenCheckinSelector && openCheckinSelector !== isOpen) {
      onChangeOpenCheckinSelector(isOpen);
    }
  };

  render() {
    const {
      checkInDate,
      checkOutDate,
      className,
      disabled,
      formFieldClassName,
      i18n: { translate, translatePlural },
      lightLabel,
      masonry,
      openCheckinSelector,
      priceData,
      showLabel,
      showNights,
    } = this.props;
    const { isCheckOutDateOpen } = this.state;

    const inputProps = {
      className: cls(!showNights && 'DateRangeSelector__input'),
      disabled,
      readOnly: true,
    };

    const checkInFieldId = `checkin${masonry ? '-masonry' : ''}`;
    const checkOutFieldId = `checkout${masonry ? '-masonry' : ''}`;
    const checkInLabel = translate('SearchControls_label_CheckIn');
    const checkOutLabel = translate('SearchControls_label_CheckOut');

    return (
      <div
        className={cls(
          'DateRangeSelector',
          className,
          showNights && 'DateRangeSelector__showNights',
        )}
      >
        <FormField
          fieldId={checkInFieldId}
          label={showLabel ? checkInLabel : undefined}
          lightLabel={lightLabel}
          className={cls(
            formFieldClassName,
            showNights && 'DateRangeSelector__checkInForm',
          )}
        >
          <DatePicker
            id={checkInFieldId}
            dateData={priceData}
            onDateSelect={this.handleCheckInChange}
            startDate={checkInDate}
            endDate={checkOutDate}
            date={checkInDate}
            inputProps={{
              ...inputProps,
              placeholder: checkInLabel,
              'data-testid': 'checkin-selector',
            }}
            popperModifiers={[
              {
                name: 'flip',
                options: { enabled: false },
              },
            ]}
            isOpen={openCheckinSelector}
            onOpenChange={this.handleCheckInOpenChange}
          />
        </FormField>
        {showNights && (
          <div className={cls('DateRangeSelector__nights')}>
            <BpkText>
              {translatePlural(
                'SelectionSummary_label_nights',
                stayLength({ checkIn: checkInDate, checkOut: checkOutDate }),
                'number',
              )}
            </BpkText>
          </div>
        )}
        <FormField
          fieldId={checkOutFieldId}
          label={showLabel ? checkOutLabel : undefined}
          lightLabel={lightLabel}
          className={cls(
            formFieldClassName,
            showNights && 'DateRangeSelector__checkoutForm',
          )}
        >
          <DatePicker
            id={checkOutFieldId}
            dateData={priceData}
            onDateSelect={this.handleCheckOutChange}
            startDate={checkInDate}
            endDate={checkOutDate}
            date={checkOutDate}
            inputProps={{
              ...inputProps,
              placeholder: checkOutLabel,
              'data-testid': 'checkout-selector',
            }}
            popperModifiers={[
              {
                name: 'flip',
                options: { enabled: false },
              },
            ]}
            isOpen={isCheckOutDateOpen}
            onOpenChange={this.handleCheckOutOpenChange}
            isCheckOut
          />
        </FormField>
      </div>
    );
  }
}

export default withI18n(withPriceData(DateRangeSelector));
