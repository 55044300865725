import React, { useRef } from 'react';

import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';
import BpkCheckbox from '@skyscanner/backpack-web/bpk-component-checkbox';
import BpkTooltip from '@skyscanner/backpack-web/bpk-component-tooltip';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

import type { I18nService } from 'common/src/types/i18n';

import { withI18n } from '../../../../../skyscanner-application/i18n';

import type { ItemType } from '../types';

import STYLES from '../Preference.scss';

const cls = cssModules(STYLES);

type Props = {
  item: ItemType;
  i18n: I18nService;
  checked: boolean;
  disabled: boolean;
  onChange: Function;
};

const FilterItem = ({
  checked,
  disabled,
  i18n,
  item: { filterClassName, id, key, tip, title, value },
  onChange,
}: Props) => {
  const target = useRef(null);

  if (title) {
    return (
      <BpkText
        key={title.key}
        textStyle={TEXT_STYLES.label2}
        className={cls(title.filterClassName)}
      >
        {i18n.translate(title.key)}
      </BpkText>
    );
  }

  return (
    <BpkTooltip
      id={`preference-tooltip-${key}`}
      ariaLabel={i18n.translate(tip)}
      key={key}
      target={
        <span ref={target}>
          <BpkCheckbox
            className={cls(filterClassName)}
            name={key}
            onChange={() => onChange(id, value)}
            label={i18n.translate(key)}
            checked={checked}
            disabled={disabled}
            white
          />
        </span>
      }
    >
      {i18n.translate(tip)}
    </BpkTooltip>
  );
};

export default withI18n(FilterItem);
