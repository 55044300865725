import React from 'react';

import BpkModal from '@skyscanner/backpack-web/bpk-component-modal';

import type { I18nService } from 'common/src/types/i18n';

import ScrollableDateRangeSelector from '../../ScrollableDateRangeSelector';
import RouteLink from '../../../RouteLink';
import HashRoute from '../../../HashRoute';
import { HASH_CHECK_IN_CHECK_OUT } from '../../../../services/routes';
import DateRange from '../DateRange';
import { getApplicationElement } from '../../../Common/ApplicationElements';
import { withI18n } from '../../../../skyscanner-application/i18n';

import type { Maybe, ModalClose } from '../../../types';

type Props = {
  i18n: I18nService;
  startDate: Maybe<Date>;
  endDate: Maybe<Date>;
  onApply: Function;
  isWithBannerAlert?: boolean;
  dateRangeClassName?: string;
};

const onOpenModal = () => {
  window.name = 'DVSearchModalIsOpen';
};

const FullScreenScrollableDateRangeSelector = ({
  dateRangeClassName,
  endDate,
  i18n,
  isWithBannerAlert,
  onApply,
  startDate,
}: Props) => (
  <>
    <RouteLink
      shouldGoBack={false}
      href={HASH_CHECK_IN_CHECK_OUT}
      onClick={onOpenModal}
      render={(onClickLink) => (
        <DateRange
          onClickLink={onClickLink}
          checkInDate={startDate}
          checkOutDate={endDate}
          className={dateRangeClassName}
        />
      )}
    />
    <HashRoute
      hash={HASH_CHECK_IN_CHECK_OUT}
      render={({
        onClose,
      }: {
        // eslint-disable-next-line react/no-unused-prop-types
        onClose: ModalClose;
      }) => (
        <BpkModal
          isOpen
          onClose={onClose}
          getApplicationElement={getApplicationElement}
          padded={false}
          title={i18n.translate('Explore_label_Dates')}
          id="explore label dates"
        >
          <ScrollableDateRangeSelector
            startDate={startDate}
            endDate={endDate}
            onApply={onApply}
            onClose={onClose}
            isWithBannerAlert={isWithBannerAlert}
          />
        </BpkModal>
      )}
    />
  </>
);

export default withI18n(FullScreenScrollableDateRangeSelector);
