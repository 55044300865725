import React from 'react';
import type { ReactNode } from 'react';

import BpkLabel from '@skyscanner/backpack-web/bpk-component-label';
import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import type { Maybe } from '../../types';

import STYLES from './FormField.scss';

type Props = {
  fieldId: string;
  label?: string;
  children: ReactNode;
  className?: Maybe<string>;
  lightLabel?: boolean;
};

const cls = cssModules(STYLES);

const FormField = ({
  children,
  className,
  fieldId,
  label,
  lightLabel,
}: Props) => {
  const classNames = [STYLES.FormField];
  if (className) {
    classNames.push(className);
  }

  if (!label) {
    classNames.push(STYLES.FormField__withoutLabel);
  }

  return (
    <div className={classNames.join(' ')}>
      <BpkLabel
        htmlFor={fieldId}
        className={cls(lightLabel && 'FormField__label--light')}
        title={label}
      >
        {label}
      </BpkLabel>
      {children}
    </div>
  );
};

FormField.defaultProps = {
  label: null,
  className: null,
  lightLabel: false,
};

export default FormField;
