import React from 'react';

import BpkSmallChevronLeftIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/chevron-left';
import BpkSmallCloseIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/close';
import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';
import BpkNavigationBar, {
  BpkNavigationBarIconButton,
} from '@skyscanner/backpack-web/bpk-component-navigation-bar';
import { withRtlSupport } from '@skyscanner/backpack-web/bpk-component-icon';
import BpkText from '@skyscanner/backpack-web/bpk-component-text';

import type { I18nService } from 'common/src/types/i18n';

import { withI18n } from '../../../skyscanner-application/i18n';

import STYLES from './BackHeader.scss';

type Props = {
  i18n: I18nService;
  onClick: Function;
  showClose?: boolean;
  className?: string;
  buttonClassName?: string;
  title?: string;
  opacity?: number;
};

const AlignedChevronLeft = withRtlSupport(BpkSmallChevronLeftIcon);
const AlignedClose = withRtlSupport(BpkSmallCloseIcon);

const cls = cssModules(STYLES);

const BackHeader = ({
  buttonClassName,
  className,
  i18n: { translate },
  onClick,
  opacity,
  showClose,
  title,
}: Props) => {
  const CloseIcon = showClose ? AlignedClose : AlignedChevronLeft;

  return (
    <BpkNavigationBar
      className={cls('BackHeader', className)}
      title={
        title && (
          <BpkText tagName="p" style={{ opacity }}>
            {title}
          </BpkText>
        )
      }
      sticky
      leadingButton={
        <BpkNavigationBarIconButton
          data-testid="back-header"
          className={cls('BackHeader__button', buttonClassName)}
          onClick={onClick}
          icon={CloseIcon}
          label={translate('PageErrorBoundary_ErrorCard_button')}
        />
      }
    />
  );
};

BackHeader.defaultProps = {
  showClose: false,
  opacity: 1,
};

export default withI18n(BackHeader);
