import React from 'react';

import { BpkButtonV2 } from '@skyscanner/backpack-web/bpk-component-button';
import BpkLargeAdultIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/adult';
import BpkInput from '@skyscanner/backpack-web/bpk-component-input';
import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import type { I18nService } from 'common/src/types/i18n';

import { HASH_GUESTS_ROOMS_CHILDREN } from '../../../../services/routes';
import { withI18n } from '../../../../skyscanner-application/i18n';
import RouteLink from '../../../RouteLink';
import GuestsRoomsChildren from '../GuestsRoomsChildren';
import getGuestsRoomsChildrenValue from '../getGuestsRoomsChildrenValue';
import withHashRoutePortal from '../../../HashRoute/withHashRoutePortal';
import FullScreenWrapper from '../../../FullScreenWrapper';

import type { Maybe } from '../../../types';

import STYLES from './GuestsRoomsChildrenPortal.scss';

const FullScreenRoute = withHashRoutePortal(FullScreenWrapper);

const cls = cssModules(STYLES);

type Props = {
  i18n: I18nService;
  adults: number;
  rooms: number;
  childrenAges: string[];
  className?: Maybe<string>;
  guestsRoomsFieldId: string;
  isApplyButtonDisabled?: boolean;
  onlyShowGuestsNumber?: boolean;
  onOpen: Function;
  onClose: Function;
  onGuestsRoomsChildrenChanged: (
    rooms: number,
    adults: number,
    childrenAges: string[],
  ) => void;
};

const GuestsRoomsChildrenPortal = ({
  adults,
  childrenAges,
  className,
  guestsRoomsFieldId,
  i18n,
  isApplyButtonDisabled,
  onClose,
  onGuestsRoomsChildrenChanged,
  onOpen,
  onlyShowGuestsNumber,
  rooms,
}: Props) => (
  <div className={cls('GuestsRoomsChildrenPortal', className)}>
    <RouteLink
      shouldGoBack={false}
      href={HASH_GUESTS_ROOMS_CHILDREN}
      onClick={onOpen}
      render={(onClickLink: any) => (
        <div
          role="button"
          tabIndex={0}
          onKeyUp={() => {}}
          onClick={onClickLink}
          className={cls(
            onlyShowGuestsNumber && 'GuestsRoomsChildrenPortal__input',
          )}
          data-test-id={guestsRoomsFieldId}
          data-testid={guestsRoomsFieldId}
        >
          {onlyShowGuestsNumber ? (
            <>
              <BpkLargeAdultIcon
                className={cls('GuestsRoomsChildrenPortal__icon')}
              />
              <button type="button">
                {adults + (childrenAges ? childrenAges.length : 0)}
              </button>
            </>
          ) : (
            <BpkInput
              id="guests-rooms-children-portal"
              name="guests-rooms-children-portal"
              readOnly
              value={getGuestsRoomsChildrenValue({
                adults,
                rooms,
                childrenAges,
                translatePlural: i18n.translatePlural,
              })}
            />
          )}
        </div>
      )}
    />
    <FullScreenRoute
      hash={HASH_GUESTS_ROOMS_CHILDREN}
      headline={i18n.translate('HotelStay_label_guests_rooms')}
      renderFooter={(closeProtal: Function) => (
        <BpkButtonV2
          disabled={isApplyButtonDisabled}
          onClick={() => {
            closeProtal();
            /**
             * On DV page, when onsubmit, a new history record will be pushed to browser history stack.
             * However, when close modal will triggle history.goBack, this method is asynthorous, avoiding
             * it triggled after new search cycle, so put onSubmit in setTimeout
             */
            setTimeout(() => {
              onClose();
            }, 0);
          }}
          className={cls('GuestsRoomsChildrenPortal__applyButton')}
        >
          {i18n.translate('Button_apply')}
        </BpkButtonV2>
      )}
      footerClassName={cls('GuestsRoomsChildrenPortal__footer')}
      headerClassName={cls('GuestsRoomsChildrenPortal__header')}
      showCloseHeader
    >
      <GuestsRoomsChildren
        adults={adults}
        rooms={rooms}
        childrenAges={childrenAges}
        onGuestsRoomsChildrenChanged={onGuestsRoomsChildrenChanged}
      />
    </FullScreenRoute>
  </div>
);

GuestsRoomsChildrenPortal.defaultProps = {
  className: null,
  isApplyButtonDisabled: false,
  onlyShowGuestsNumber: false,
};

export default withI18n(GuestsRoomsChildrenPortal);
