import React from 'react';

import { CALENDAR_SELECTION_TYPE } from '@skyscanner/backpack-web/bpk-component-calendar';
import BpkDatepicker from '@skyscanner/backpack-web/bpk-component-datepicker';

import {
  minCheckInDate,
  minCheckOutDate,
  maxCheckInDate,
  maxCheckOutDate,
} from 'common/src/services/guest-stay';
import type { I18nService } from 'common/src/types/i18n';

import { DATE_FORMAT, withI18n } from '../../../../skyscanner-application/i18n';
import { withFeatureTests } from '../../../../skyscanner-application/feature-tests';
import { getApplicationElement } from '../../../Common/ApplicationElements';

import type { FeatureTests } from '../../../../skyscanner-application/types';
import type { Maybe } from '../../../types';
import type { DateMedianPrice } from '../../../../types/flexible-dates-response';

type Props = {
  i18n: I18nService;
  id: string;
  date: Date;
  startDate: Date;
  endDate: Date;
  dateData: Maybe<DateMedianPrice>;
  featureTests: FeatureTests;
  isCheckOut?: boolean;
  isOpen?: boolean;
  onDateSelect: Function;
  onOpenChange?: (isOpen: boolean) => void;
  inputProps?: {
    className?: string;
    disabled?: boolean;
    readOnly?: boolean;
    placeholder?: string;
    ['data-testid']: string;
  };
  popperModifiers?: Array<{
    name: string;
    options: {
      enabled: boolean;
    };
  }>;
};

const defaultProps = {
  isCheckOut: false,
};

const DatePicker = ({
  date,
  dateData,
  endDate,
  featureTests,
  i18n: { formatDate, getDaysOfWeek, getFirstDay, translate },
  id,
  isCheckOut,
  onDateSelect,
  onOpenChange,
  startDate,
  ...rest
}: Props) => {
  const selectionConfiguration = {
    type: CALENDAR_SELECTION_TYPE.single,
    date,
  };

  const handleDateSelect = (newDate: Date) => {
    onDateSelect(newDate);
  };

  const formatDateFull = (dt: Date) => formatDate(dt, DATE_FORMAT.FULL);

  const formatMonth = (dt: Date) => formatDate(dt, DATE_FORMAT.YEAR_MONTH);

  const daysOfWeek = getDaysOfWeek();
  const weekStartsOn = getFirstDay();

  let title;
  let minDate;
  let maxDate;
  if (isCheckOut) {
    title = translate('SearchControls_label_CheckOut');
    minDate = minCheckOutDate();
    maxDate = maxCheckOutDate();
  } else {
    title = translate('SearchControls_label_CheckIn');
    minDate = minCheckInDate();
    maxDate = maxCheckInDate();
  }

  return (
    <BpkDatepicker
      id={id}
      title={title}
      onOpenChange={onOpenChange}
      weekStartsOn={weekStartsOn}
      daysOfWeek={daysOfWeek}
      minDate={minDate}
      maxDate={maxDate}
      closeButtonText={translate('Modal_close')}
      changeMonthLabel={translate('HotelStay_label_changeMonth')}
      formatDate={formatDate}
      formatMonth={formatMonth}
      formatDateFull={formatDateFull}
      onDateSelect={handleDateSelect}
      selectionConfiguration={selectionConfiguration}
      getApplicationElement={getApplicationElement}
      {...rest}
    />
  );
};

DatePicker.defaultProps = defaultProps;

export default withFeatureTests(withI18n(DatePicker));
