import React, { Component } from 'react';

import differenceInDays from 'date-fns/differenceInDays';
import isDate from 'date-fns/isDate';
import isValid from 'date-fns/isValid';

import {
  BpkCalendarGridHeader,
  composeCalendar,
  withCalendarState,
} from '@skyscanner/backpack-web/bpk-component-calendar';
import {
  BpkScrollableCalendarGridList,
  CALENDAR_SELECTION_TYPE,
} from '@skyscanner/backpack-web/bpk-component-scrollable-calendar';

import { parseDate } from 'common/src/utils/machine-date-utils';
import {
  minCheckInDate,
  maxCheckOutDate,
} from 'common/src/services/guest-stay';
import type { I18nService } from 'common/src/types/i18n';

import logger from '../../services/logger';
import { DATE_FORMAT, withI18n } from '../../skyscanner-application/i18n';

import CalendarDate from './CalendarDate';

import type { Maybe } from '../types';

import STYLES from './ScrollableRangeCalendar.scss';

export const truncDate = (dateParam: Maybe<Date>) => {
  if (!dateParam) return dateParam;
  let date = dateParam;
  if (!isDate(dateParam)) {
    date = parseDate(dateParam);
  }
  if (!isValid(date)) {
    logger.warn('The param is invalid Date in ScrollableRangeCalendar', {
      date: JSON.stringify(dateParam),
    });
    return null;
  }
  // eslint-disable-next-line skyscanner-dates/no-new-date-with-args
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

type Props = {
  i18n: I18nService;
  onDateRangeChange: Function;
  startDate?: Maybe<Date>;
  endDate?: Maybe<Date>;
};

type State = {
  selectionConfiguration: {
    type: string;
    startDate: Maybe<Date>;
    endDate?: Maybe<Date>;
  };
};

const defaultProps = {
  startDate: null,
  endDate: null,
};

const RangeCalendar = withCalendarState(
  composeCalendar(
    null,
    BpkCalendarGridHeader,
    BpkScrollableCalendarGridList,
    CalendarDate,
  ),
);

class ScrollableRangeCalendar extends Component<Props, State> {
  static defaultProps = defaultProps;

  constructor(props: Props) {
    super(props);

    const { endDate, startDate } = props;
    this.state = {
      selectionConfiguration: {
        type: CALENDAR_SELECTION_TYPE.range,
        startDate: truncDate(startDate),
        endDate: truncDate(endDate),
      },
    };
  }

  onDateSelect = (startDate: Date, endDate: Maybe<Date>) => {
    if (startDate) {
      let selectedStartDate = startDate;
      let selectedEndDate = endDate;
      if (!endDate) {
        selectedEndDate = null;
      } else if (differenceInDays(startDate, parseDate(endDate)) < -30) {
        selectedStartDate = endDate;
        selectedEndDate = null;
      }
      this.setState({
        selectionConfiguration: {
          type: CALENDAR_SELECTION_TYPE.range,
          startDate: selectedStartDate,
          endDate: selectedEndDate,
        },
      });
      this.props.onDateRangeChange(selectedStartDate, selectedEndDate);
    }
  };

  formatDateFull = (date: Date) =>
    this.props.i18n.formatDate(date, DATE_FORMAT.FULL);

  formatMonth = (date: Date) =>
    this.props.i18n.formatDate(date, DATE_FORMAT.YEAR_MONTH);

  render() {
    const {
      i18n: { getDaysOfWeek, getFirstDay },
    } = this.props;

    const minDate = minCheckInDate();
    const maxDate = maxCheckOutDate();

    const calendarProps = {
      className: STYLES.ScrollableRangeCalendar__inner,
      daysOfWeek: getDaysOfWeek(),
      markToday: false,
      selectionConfiguration: this.state.selectionConfiguration,
      onDateSelect: this.onDateSelect,
      weekDayKey: 'nameNarrow',
      enableSelection: true,
      formatDate: this.formatDateFull,
      formatDateFull: this.formatDateFull,
      formatMonth: this.formatMonth,
      id: 'calendar',
      initialSelectedDate: null,
      minDate,
      maxDate,
      weekStartsOn: Number(getFirstDay()),
      DateComponent: CalendarDate,
      fixedWidth: false,
      selectTodayDate: false,
    };

    // @ts-expect-error weekStartsOn: 0 | 1 | 2 | 3 | 4 | 5 | 6 not match with number
    return <RangeCalendar {...calendarProps} />;
  }
}

export default withI18n(ScrollableRangeCalendar);
