import React from 'react';

import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

import {
  getSubheadingValue,
  getSuggestionIcon,
  getSuggestionValue,
} from '../../searchControlHelper';

import type { AutosuggestSuggestion, Maybe } from '../../../types';

import STYLES from './PopularDestination.scss';

type Props = {
  index: number;
  onSuggestionSelected: Function;
  onBlur: Function;
  popular: AutosuggestSuggestion;
  isMobile?: boolean;
  onSuggestionsClearRequested?: Maybe<Function>;
};

const cls = cssModules(STYLES);

const PopularDestination = ({
  index,
  isMobile,
  onBlur,
  onSuggestionSelected,
  onSuggestionsClearRequested,
  popular,
}: Props) => {
  const Icon = getSuggestionIcon(popular);
  return (
    <div
      className={cls('PopularDestination')}
      key={popular.entityId}
      role="button"
      onKeyUp={() => {}}
      tabIndex={0}
      onClick={(e) => {
        e.stopPropagation();
        onSuggestionSelected(
          {},
          {
            suggestion: popular,
            suggestionIndex: index,
            suggestionValue: popular.entity,
            method: 'click',
            isPopularDestination: true,
          },
        );
        onBlur(e, {
          highlightedSuggestion: popular,
        });

        if (onSuggestionsClearRequested) {
          onSuggestionsClearRequested();
        }
      }}
    >
      {isMobile ? (
        getSuggestionValue(popular)
      ) : (
        <>
          <Icon className={cls('PopularDestination__icon')} />
          <div className={cls('PopularDestination__inner')}>
            <BpkText tagName="p" className={cls('PopularDestination__value')}>
              {getSuggestionValue(popular)}
            </BpkText>
            <BpkText
              textStyle={TEXT_STYLES.caption}
              tagName="p"
              className={cls('PopularDestination__subHeading')}
            >
              {getSubheadingValue(popular)}
            </BpkText>
          </div>
        </>
      )}
    </div>
  );
};

PopularDestination.defaultProps = {
  onSuggestionsClearRequested: null,
  isMobile: false,
};

export default PopularDestination;
