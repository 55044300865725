import startOfDay from 'date-fns/startOfDay';
import addDays from 'date-fns/addDays';

import { now } from '../../utils/wrap-date';

import { MAX_DAYS_IN_ADVANCE, areStayDatesValid } from './are-stay-dates-valid';

export const applyCheckOutDateRestrictions = (
  checkIn: Date,
  checkOut: Date,
) => {
  if (!areStayDatesValid({ checkIn, checkOut })) {
    return addDays(checkIn, 1);
  }

  return checkOut;
};

export const applyCheckInDateRestrictions = (checkIn: Date, checkOut: Date) => {
  if (!areStayDatesValid({ checkIn, checkOut })) {
    return addDays(checkOut, -1);
  }

  return checkIn;
};

export const applyNumGuestsRestrictions = (
  numGuests: number,
  numRooms: number,
) => Math.max(numGuests, numRooms);

export const applyNumRoomsRestrictions = (
  numGuests: number,
  numRooms: number,
) => Math.min(numGuests, numRooms);

export const minCheckInDate = () => startOfDay(now());

export const minCheckOutDate = () => startOfDay(addDays(now(), 1));

export const maxCheckInDate = () =>
  addDays(minCheckInDate(), MAX_DAYS_IN_ADVANCE);

export const maxCheckOutDate = () =>
  addDays(minCheckOutDate(), MAX_DAYS_IN_ADVANCE + 1);
