import React, { Component } from 'react';

import BpkInput, {
  INPUT_TYPES,
} from '@skyscanner/backpack-web/bpk-component-input';
import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';
import TickIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/tick';
import {
  ALERT_TYPES,
  BpkBannerAlertExpandable,
  withBannerAlertState,
} from '@skyscanner/backpack-web/bpk-component-banner-alert';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

import type { I18nService } from 'common/src/types/i18n';

import { DATE_FORMAT, withI18n } from '../../../skyscanner-application/i18n';
import ScrollableRangeCalendar from '../../ScrollRangeCalendar/ScrollableRangeCalendar';

import PriceInfoDialog from './PriceInfoDialog';
import ApplyFooter from './ApplyFooter';

import type { Maybe } from '../../types';

import STYLES from './ScrollableDateRangeSelector.scss';

type Props = {
  i18n: I18nService;
  onApply: Function;
  onClose: Function;
  startDate: Maybe<Date>;
  endDate?: Maybe<Date>;
  isWithBannerAlert?: boolean;
  isWithPriceInfo?: boolean;
};

type State = {
  startDate: Maybe<Date>;
  endDate: Maybe<Date>;
};

const defaultProps = {
  endDate: null,
  isWithBannerAlert: false,
  isWithPriceInfo: undefined,
};

const cls = cssModules(STYLES);

const BannerAlertExpandableState = withBannerAlertState(
  BpkBannerAlertExpandable,
);

class ScrollableDateRangeSelector extends Component<Props, State> {
  static defaultProps = defaultProps;

  constructor(props: Props) {
    super(props);
    const { endDate, startDate } = props;
    this.state = {
      startDate,
      endDate,
    };
  }

  onSubmit = () => {
    const { onApply, onClose } = this.props;
    const { endDate, startDate } = this.state;

    onClose();
    onApply(startDate, endDate);
  };

  onDateRangeChange = (startDate: Date, endDate: Date) => {
    this.setState({ startDate, endDate });
  };

  render() {
    const { endDate, startDate } = this.state;
    const {
      i18n: { formatDate: formatDateI18n, translate },
      isWithBannerAlert,
      isWithPriceInfo,
    } = this.props;

    return (
      <div className={cls('ScrollableDateRangeSelector')}>
        <div className={cls('ScrollableDateRangeSelector__header')}>
          {isWithBannerAlert && (
            <div className={cls('ScrollableDateRangeSelector__alert')}>
              <BpkText tagName="p">
                <BannerAlertExpandableState
                  message={translate('DateRangeSelector_banner_title')}
                  type={ALERT_TYPES.NEUTRAL}
                  toggleButtonLabel={translate('Button_viewMore')}
                >
                  {translate('DateRangeSelector_banner_content')}
                </BannerAlertExpandableState>
              </BpkText>
            </div>
          )}
          {isWithPriceInfo && (
            <div className={cls('ScrollableDateRangeSelector__subtitle')}>
              <BpkText textStyle={TEXT_STYLES.footnote} tagName="p">
                {translate('HotelStay_modal_calendar_estimated_prices')}
              </BpkText>
              <PriceInfoDialog headerIcon={<TickIcon />} dismissible={false} />
            </div>
          )}
        </div>

        <div className={cls('ScrollableDateRangeSelector__content')}>
          <div className={cls('ScrollableDateRangeSelector__dateRange')}>
            <div
              className={cls(
                'ScrollableDateRangeSelector__date',
                'ScrollableDateRangeSelector__date--checkIn',
              )}
            >
              <BpkText textStyle={TEXT_STYLES.label2} tagName="p">
                {translate('SelectionSummary_label_checkIn')}
              </BpkText>
              <BpkInput
                id="scrollableDateCheckInId"
                type={INPUT_TYPES.text}
                name="scrollableDateCheckInId"
                className={cls('ScrollableDateRangeSelector__date--input')}
                value={
                  startDate
                    ? formatDateI18n(
                        startDate,
                        DATE_FORMAT.MONTH_DAY_ABBR,
                      ).replace(',', '')
                    : translate('HotelStay_label_checkIn')
                }
                placeholder={translate('HotelStay_label_checkIn')}
                readOnly
              />
            </div>
            <div
              className={cls(
                'ScrollableDateRangeSelector__date',
                'ScrollableDateRangeSelector__date--checkOut',
              )}
            >
              <BpkText textStyle={TEXT_STYLES.label2} tagName="p">
                {translate('SelectionSummary_label_checkOut')}
              </BpkText>
              <BpkInput
                id="scrollableDateCheckOutId"
                type={INPUT_TYPES.text}
                name="scrollableDateCheckOutId"
                className={cls('ScrollableDateRangeSelector__date--input')}
                value={
                  endDate
                    ? formatDateI18n(
                        endDate,
                        DATE_FORMAT.MONTH_DAY_ABBR,
                      ).replace(',', '')
                    : translate('HotelStay_label_checkOut')
                }
                placeholder={translate('HotelStay_label_checkOut')}
                readOnly
                disabled={!endDate}
              />
            </div>
          </div>
          <ScrollableRangeCalendar
            startDate={startDate}
            endDate={endDate}
            onDateRangeChange={this.onDateRangeChange}
          />
          <ApplyFooter
            checkIn={startDate}
            checkOut={endDate}
            disabled={
              !startDate ||
              !endDate ||
              (startDate === this.props.startDate &&
                endDate === this.props.endDate)
            }
            onApply={this.onSubmit}
          />
        </div>
      </div>
    );
  }
}

export default withI18n(ScrollableDateRangeSelector);
