import React from 'react';

import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import type { Configs } from 'common/src/types/configs';
import type { I18nService } from 'common/src/types/i18n';

import { withI18n } from '../../../skyscanner-application/i18n';
import { withConfiguration } from '../../../skyscanner-application/configuration';
import GuestsRoomsChildrenSelect from '../../Common/GuestsRoomsChildrenSelect';
import DestinationSelector from '../DestinationSelector';
import BpkLoadingButton from '../../Common/BpkLoadingButton';

import FullScreenScrollableDateRangeSelector from './FullScreenScrollableDateRangeSelector';

import type {
  AutosuggestSuggestionsOrPopularDestinations,
  Destination,
  Maybe,
} from '../../types';

import STYLES from './HorizontalLayout.scss';

const cls = cssModules(STYLES);

type Props = {
  i18n: I18nService;
  configs: Configs;
  destination?: Destination;
  checkInDate: Maybe<Date>;
  checkOutDate: Maybe<Date>;
  destinationLabel: string;
  childrenAges: string[];
  adults: number;
  rooms: number;
  suggestions: AutosuggestSuggestionsOrPopularDestinations;
  onChangeInputValue: Function;
  onClearSuggestions: Function;
  onSuggestionSelected: Function;
  onSuggestionsFetchRequested: Function;
  onChangeOpenCheckinSelector: Function;
  onDatesRangeApply: Function;
  getGuestsRoomsChildren: Function;
  lightLabel?: boolean;
  validDestination: Maybe<boolean>;
  submittingForm?: boolean;
  showSearchButton: boolean;
  arrangeInline?: boolean;
};

const HorizontalLayout = ({
  adults,
  arrangeInline,
  checkInDate,
  checkOutDate,
  childrenAges,
  configs,
  destination,
  destinationLabel,
  getGuestsRoomsChildren,
  i18n: { translate },
  lightLabel,
  onChangeInputValue,
  onChangeOpenCheckinSelector,
  onClearSuggestions,
  onDatesRangeApply,
  onSuggestionSelected,
  onSuggestionsFetchRequested,
  rooms,
  showSearchButton,
  submittingForm,
  suggestions,
  validDestination,
}: Props) => (
  <>
    <div className={cls('HorizontalLayout')}>
      <DestinationSelector
        destinationLabel={destinationLabel}
        destination={destination}
        lightLabel={lightLabel}
        inputClassName={cls('HorizontalLayout__input')}
        onSuggestionSelected={onSuggestionSelected}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onClearSuggestions}
        suggestions={suggestions}
        onChangeOpenCheckinSelector={onChangeOpenCheckinSelector}
        onChangeInputValue={onChangeInputValue}
        valid={validDestination}
      />

      <div
        className={cls('HorizontalLayout__datesGuest')}
        data-test-id="search-controls-datesguest"
      >
        <FullScreenScrollableDateRangeSelector
          startDate={checkInDate}
          endDate={checkOutDate}
          onApply={onDatesRangeApply}
          isWithBannerAlert={configs && configs.enableCalendarWithPrice}
          dateRangeClassName={
            arrangeInline && cls('HorizontalLayout__dateRange')
          }
        />
        <GuestsRoomsChildrenSelect
          className={cls(
            'HorizontalLayout__guestsRoomsChildren',
            arrangeInline &&
              'HorizontalLayout__guestsRoomsChildren--arrangeInline',
          )}
          lightLabel={lightLabel}
          adults={adults}
          rooms={rooms}
          childrenAges={childrenAges}
          getGuestsRoomsChildren={getGuestsRoomsChildren}
        />
      </div>
      {showSearchButton && arrangeInline && (
        <BpkLoadingButton
          iconOnly
          className={cls('HorizontalLayout__searchButton')}
          loading={submittingForm}
          type="submit"
          featured
          data-test-id="search-button"
        />
      )}
    </div>
    {showSearchButton && !arrangeInline && (
      <BpkLoadingButton
        className={cls('HorizontalLayout__button')}
        loading={submittingForm}
        type="submit"
        large
        featured
        data-test-id="search-button"
      >
        <span>{translate('SearchControls_label_Submit')}</span>
      </BpkLoadingButton>
    )}
  </>
);

HorizontalLayout.defaultProps = {
  lightLabel: false,
  submittingForm: false,
};

export default withI18n(withConfiguration(HorizontalLayout));
