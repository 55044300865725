import type { MouseEvent } from 'react';
import React from 'react';

import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';
import {
  BpkButtonV2,
  BUTTON_TYPES,
} from '@skyscanner/backpack-web/bpk-component-button';

import type { I18nService } from 'common/src/types/i18n';

import GuestsRoomsChildrenSelect from '../../Common/GuestsRoomsChildrenSelect';
import DateRangeSelector from '../DateRangeSelector';
import BpkLoadingButton from '../../Common/BpkLoadingButton';
import { withI18n } from '../../../skyscanner-application/i18n';

import type { Maybe } from '../../types';

import STYLES from './ChappedLayoutSEO.scss';

const cls = cssModules(STYLES);

type Props = {
  checkInDate: Maybe<Date>;
  checkOutDate: Maybe<Date>;
  childrenAges: string[];
  adults: number;
  rooms: number;
  onDatesChanged: Function;
  onChangeOpenCheckinSelector: Function;
  onSubmit: Function;
  openCheckinSelector?: boolean;
  getGuestsRoomsChildren: Function;
  lightLabel?: boolean;
  i18n: I18nService;
};

const ChappedLayoutSEO = ({
  adults,
  checkInDate,
  checkOutDate,
  childrenAges,
  getGuestsRoomsChildren,
  i18n: { translate },
  lightLabel,
  onChangeOpenCheckinSelector,
  onDatesChanged,
  onSubmit,
  openCheckinSelector,
  rooms,
}: Props) => (
  <div className={cls('ChappedLayoutSEO')}>
    <div
      className={cls('ChappedLayoutSEO__inputsDates')}
      data-test-id="search-controls-datesguest"
    >
      <DateRangeSelector
        lightLabel={lightLabel}
        // @ts-expect-error TS2322: Type 'Maybe<Date>' is not assignable to type 'Date'.
        checkInDate={checkInDate}
        // @ts-expect-error TS2322: Type 'Maybe<Date>' is not assignable to type 'Date'.
        checkOutDate={checkOutDate}
        onDatesChanged={onDatesChanged}
        openCheckinSelector={openCheckinSelector}
        onChangeOpenCheckinSelector={onChangeOpenCheckinSelector}
        showNights
      />
    </div>
    <GuestsRoomsChildrenSelect
      className={cls('ChappedLayoutSEO__guestsRoomsChildren')}
      lightLabel={lightLabel}
      adults={adults}
      rooms={rooms}
      childrenAges={childrenAges}
      getGuestsRoomsChildren={getGuestsRoomsChildren}
    />
    <BpkButtonV2
      className={cls('SearchButton')}
      data-test-id="search-button-SEO"
      type={BUTTON_TYPES.primary}
      large
      onClick={(_e: MouseEvent<HTMLButtonElement>) => onSubmit()}
    >
      {translate('RoomsAndRates_HDP_search_rooms_rates_button')}
    </BpkButtonV2>
  </div>
);

ChappedLayoutSEO.defaultProps = {
  lightLabel: false,
};

export default withI18n(ChappedLayoutSEO);
