import React from 'react';

import isEqual from 'lodash/isEqual';

import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import FormField from '../../SearchControls/FormField';
import BpkBreakpoint, { BREAKPOINTS } from '../BpkBreakpoint';

import GuestsRoomsChildrenPortal from './GuestsRoomsChildrenPortal';
import GuestsRoomsChildrenPopover from './GuestsRoomsChildrenPopover';

import type { Maybe } from '../../types';

import STYLES from './GuestsRoomsChildrenSelect.scss';

type Props = {
  adults: number;
  rooms: number;
  childrenAges: string[];
  getGuestsRoomsChildren: Function;
  label?: string;
  lightLabel?: boolean;
  collapsed?: boolean;
  masonry?: boolean;
  className?: Maybe<string>;
  stayEditClassName?: string;
};

type State = {
  isOpen: boolean;
  rooms: number;
  adults: number;
  childrenAges: string[];
  adultChanged?: Maybe<boolean>;
  childrenAgesChanged?: Maybe<boolean>;
  roomNumChanged?: Maybe<boolean>;
};

const defaultProps = {
  lightLabel: false,
  collapsed: false,
  masonry: false,
};

const cls = cssModules(STYLES);

class GuestsRoomsChildrenSelect extends React.Component<Props, State> {
  static defaultProps = defaultProps;

  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
      rooms: props.rooms,
      adults: props.adults,
      childrenAges: props.childrenAges,
    };
  }

  onOpen = () => {
    window.name = 'DVSearchModalIsOpen';
    const { adults, childrenAges, rooms } = this.props;
    this.setState({
      isOpen: true,
      rooms,
      adults,
      childrenAges,
    });
  };

  onClose = (shouldUpdate: boolean = true) => {
    this.setState({
      isOpen: false,
    });
    if (shouldUpdate) {
      const {
        adultChanged,
        adults,
        childrenAges,
        childrenAgesChanged,
        roomNumChanged,
        rooms,
      } = this.state;
      this.props.getGuestsRoomsChildren({
        adults,
        adultChanged,
        childrenAges,
        childrenAgesChanged,
        rooms,
        roomNumChanged,
      });
    } else {
      const { adults, childrenAges, rooms } = this.props;
      this.setState({
        rooms,
        childrenAges,
        adults,
      });
    }
  };

  onGuestsRoomsChildrenChanged = (
    rooms: number,
    adults: number,
    childrenAges: string[],
  ) => {
    if (rooms !== this.state.rooms) {
      this.setState({
        rooms,
        roomNumChanged: true,
      });
    }

    if (adults !== this.state.adults) {
      this.setState({
        adults,
        adultChanged: true,
      });
    }

    if (!isEqual(childrenAges, this.state.childrenAges)) {
      this.setState({
        childrenAges,
        childrenAgesChanged: true,
      });
    }
  };

  render() {
    const {
      adults,
      childrenAges,
      className,
      collapsed,
      label,
      lightLabel,
      masonry,
      rooms,
      stayEditClassName,
    } = this.props;
    const {
      adults: adultsState,
      childrenAges: childrenAgesState,
      isOpen,
      rooms: roomState,
    } = this.state;
    const isApplyButtonDisabled =
      rooms === roomState &&
      adults === adultsState &&
      isEqual(childrenAges, childrenAgesState);
    const guestsRoomsFieldId = `guests-rooms${collapsed ? '-collapsed' : ''}${
      masonry ? '-masonry' : ''
    }`;

    return (
      <div className={cls('GuestsRoomsChildrenSelect', className)}>
        <FormField
          className={cls(
            'GuestsRoomsChildrenSelect__formField',
            stayEditClassName,
          )}
          fieldId={guestsRoomsFieldId}
          label={label}
          lightLabel={lightLabel}
        >
          <BpkBreakpoint query={BREAKPOINTS.MOBILE}>
            {(isMobile: boolean) =>
              isMobile ? (
                <GuestsRoomsChildrenPortal
                  adults={adults}
                  rooms={rooms}
                  childrenAges={childrenAges}
                  onlyShowGuestsNumber={!label}
                  className={
                    label ? cls('GuestsRoomsChildrenSelect__portal') : null
                  }
                  isApplyButtonDisabled={isApplyButtonDisabled}
                  guestsRoomsFieldId={guestsRoomsFieldId}
                  onOpen={this.onOpen}
                  onClose={this.onClose}
                  onGuestsRoomsChildrenChanged={
                    this.onGuestsRoomsChildrenChanged
                  }
                />
              ) : (
                <GuestsRoomsChildrenPopover
                  isOpen={isOpen}
                  adults={adults}
                  rooms={rooms}
                  childrenAges={childrenAges}
                  guestsRoomsFieldId={guestsRoomsFieldId}
                  onOpen={this.onOpen}
                  onClose={this.onClose}
                  onGuestsRoomsChildrenChanged={
                    this.onGuestsRoomsChildrenChanged
                  }
                />
              )
            }
          </BpkBreakpoint>
        </FormField>
      </div>
    );
  }
}

export default GuestsRoomsChildrenSelect;
