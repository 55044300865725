import type { I18nService } from 'common/src/types/i18n';

const transformPrice = (i18n: I18nService, price: number) =>
  new Map([
    [
      'EXPENSIVE',
      {
        text: '£££',
        price: `${i18n.formatCurrency(price)}`,
      },
    ],
    [
      'MODERATE',
      {
        text: '££',
        price: `${i18n.formatCurrency(price)}`,
      },
    ],
    [
      'CHEAP',
      {
        text: '£',
        price: `${i18n.formatCurrency(price)}`,
      },
    ],
    [
      'DEFAULT',
      {
        text: ' ',
        price: '-',
      },
    ],
  ]);

export default transformPrice;
